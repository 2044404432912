<template>
  <section-wrap>
    <template #title>
      <div class="d-flex">
        <h3 class="section-title mr-5">
          Profile Settings
        </h3>
      </div>
    </template>
    <section-container
      :header="false"
    >
      <template #body>
        <v-row class="flex-column flex-md-row" no-gutters>
          <v-col class="settings-col" md="2">
            <v-list class="py-0">
              <v-list-item
                class="settings-item"
                :to="buildPath('profile')"
              >
                Edit Profile
              </v-list-item>
              <v-list-item
                class="settings-item"
                :to="buildPath('password')"
              >
                Password
              </v-list-item>
            </v-list>
          </v-col>
          <v-divider class="d-none d-md-block" vertical />
          <v-col class="settings-col settings-content">
            <router-view />
          </v-col>
        </v-row>
      </template>
    </section-container>
  </section-wrap>
</template>

<script>
import SectionWrap from '@/components/collections/SectionWrap/SectionWrap';
import SectionContainer from '@/components/collections/SectionContainer/SectionContainer';

export default {
  name: 'Settings',
  components: { SectionContainer, SectionWrap },
  methods: {
    buildPath(path) {
      return `/settings/${path}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  ::v-deep .section-content-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .settings-col {
    padding-top: $spacing-9;
    padding-bottom: $spacing-9;
    &.settings-content {
      padding-left: 40px;
    }
  }
  .settings-item {
    &:before {
      border-radius: $radius-3;
    }
    &.v-list-item--active {
      color: $primary;
    }
  }
</style>
